<template>
  <el-dialog
    append-to-body
    top="4vh"
    width="800px"
    title="产品详情"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">产品详情</div>
    </template>

    <div class="content">
      <div class="big-label mb20">产品介绍</div>
      <div class="mb20">
        <span class="label">产品标题：</span>
        <span>{{detailInfo.title}}</span>
      </div>
      <div class="mb20">
        <span class="label">产品描述：</span>
        <span>{{detailInfo.description}}</span>
      </div>
      <div class="mb20">
        <span class="label">开放时间：</span>
        <span>{{detailInfo.open_time}}</span>
      </div>
      <el-row class="mb20">
        <el-col :span="12">
          <span class="label">适用学段：</span>
          <span>{{setarr(detailInfo.period_type.split(","))}}</span>
        </el-col>
        <el-col :span="12">
          <span class="label">活动天数：</span>
          <span>{{detailInfo.days}}天</span>
        </el-col>
      </el-row>
      <div class="mb20">
        <span class="label">地址：</span>
        <span>{{detailInfo.province_str}}{{detailInfo.city_str}}{{detailInfo.area_str}}{{detailInfo.address}}</span>
      </div>
      <el-row class="mb20">
        <el-col :span="12">
          <span class="label">咨询电话：</span>
          <span>{{detailInfo.consult_mobile}}</span>
        </el-col>
        <el-col :span="12">
          <span class="label">售价：</span>
          <span class="colorDel">{{detailInfo.amount}} 元/人</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <div class="big-label mb20">产品标签</div>
      <div>
        <el-tag style="margin-right:10px;" type="info" v-for="item in detailInfo.tags" :key="item">{{item}}</el-tag>
      </div>

      <el-divider></el-divider>
      <div class="big-label mb20">产品图片</div>
      <div class="flex mb20">
        <span class="label">主图</span>
        <el-image
          style="margin-left:10px;width:218px;height:146px;border-radius:6px;"
          :src="detailInfo.image"
          fit="cover"
          :preview-src-list="[detailInfo.image]"
          ></el-image>
      </div>
      <div class="flex mb20">
        <span class="label">详情图</span>
        <el-image
          style="margin-left:10px;width:104px;height:104px;border-radius:6px;"
          v-for="item in detailInfo.images"
          :key="item"
          :src="item"
          fit="cover"
          :preview-src-list="detailInfo.images"
          ></el-image>
      </div>

      <el-divider></el-divider>
      <div class="big-label mb20">其他</div>
      <div class="flex mb20">
        <span class="label">行程安排：</span>
        <div v-html="detailInfo.schedule"></div>
      </div>
      <div class="mb20">
        <span class="label">退款须知：</span>
        <span>行程开始前{{detailInfo.allow_refund_day}}日，可申请退款; 超时不予退款</span>
      </div>
      <div class="mb20">
        <span class="label">注意事项：</span>
        <span>{{detailInfo.attention_info}}</span>
      </div>
      <div class="mb20">
        <span class="label">备注：</span>
        <span>{{detailInfo.mark}}</span>
      </div>
      
    </div>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'GoodsDetail',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        detailInfo: {
          period_type:''
        },
        accessArr:{1:'小学',2:'初中',3:'高中'},
        
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('/company/goods/detail',{ params:{ id:row.id } }).then(res => {
          if(res.code == 1) {
            this.detailInfo = res.data;
          }
        })
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      setarr(arr) {
        if(arr.length == 1) {
          return this.accessArr[arr[0]]
        } else if (arr.length == 2) {
          return this.accessArr[arr[0]] +' ， '+ this.accessArr[arr[1]]
        } else if (arr.length == 3) {
          return this.accessArr[arr[0]] +' ， '+ this.accessArr[arr[1]] +' ， '+ this.accessArr[arr[2]]
        }
      }
    }
  }
</script>

<style scoped>
.score {
  font-size: 24px;
  font-weight: bold;
  color: #F53030;
}
.content {
  font-size: 16px;
  line-height: 20px;
  padding: 0 18px;
  overflow-y: scroll;
  max-height: 600px;
}
.img {
  width: 120px;
  height: 120px;
  border-radius: 6px;
  margin: 10px 20px 10px 0;
}
.label {
  color: #999999;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.content ::v-deep .el-rate__icon {
  font-size: 30px !important;
}
.big-label {
  font-size: 16px;
  font-weight: bold;
}
</style>
