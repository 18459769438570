<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="showEdit({},0)">创建</el-button>
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="title">
            <el-input v-model="table.params.title" placeholder="产品名称">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="days">
            <el-select v-model="table.params.days" placeholder="活动天数" @change="onSearch">
              <el-option label="1天" :value="1"></el-option>
              <el-option label="2天" :value="2"></el-option>
              <el-option label="3天" :value="3"></el-option>
              <el-option label="4天" :value="4"></el-option>
              <el-option label="5天" :value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="display: flex;justify-content: space-between;padding-right:24px;">
        <el-radio-group class="radio_group_wrap" v-model="table.params.status" @change="handleTabs" size="medium">
          <el-radio-button :label="2">已上架({{table.publish}})</el-radio-button>
          <el-radio-button :label="1">审核中({{table.verify}})</el-radio-button>
          <el-radio-button :label="3">已下架({{table.unpublish}})</el-radio-button>
        </el-radio-group>
        <div style="width:480px;">
          <el-alert
            title="已下架的产品如需上架需后台重新审核，状态为审核中，通过后才会上架"
            type="warning"
            show-icon
            :closable="false">
          </el-alert>
        </div>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="curField"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:title="{row}">
        <div class="flex" style="align-items:center;">
          <el-image
            style="width: 80px; height: 40px;border-radius:2px;margin-right:10px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
            <div class="table-col-text">{{row.title}}</div>
        </div>
      </template>
      <template v-slot:status="{row}">
        <span :class="`corlor${row.status}`">{{toStr({1:'审核中',2:'已上架',3:'已下架'},row.status)}}</span>
      </template>
      <template v-slot:amount="{row}">
        <span style="color:#FD4100;font-weight: bold;">{{row.amount}}元/人</span>
      </template>
      <template v-slot:action="{row}">
        <!--1-待审核(审核中)；2-审核通过（已上架）；3-已下架  -->
        <template v-if="table.params.status != 1">
          <el-button type="text" @click='showEdit(row,1)'>复制</el-button>
          <span class="action-btn-line">|</span>
        </template>
        <template v-if="table.params.status == 3">
          <el-button type="text" @click='showEdit(row,2)'>编辑</el-button>
          <span class="action-btn-line">|</span>
        </template>
        <template v-if="table.params.status != 3">
          <el-button type="text" @click='showDetail(row)'>详情</el-button>
          <span class="action-btn-line">|</span>
        </template>
        <el-button type="text" class="colorDel" @click='handleOperate(row)'>{{row.status == 3 ? '上架' : '下架' }}</el-button>
      </template>
    </VTable>

    <edit ref="edit" :powerArr="curPowerArr" @refresh="getTable"></edit>
    <detail ref="detail" ></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'AccountList',
  components: {
    VTable,
    Edit,
    Detail
  },
  mixins: [mixinTable],
  data() {
    return {
      field1: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "days", label: "活动天数(天)", hidden: false},
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "status", label: "上架状态", hidden: false},
        { name: "commit_time", label: "提交时间", width:'160', hidden: false},
        { name: "action", label: "操作", width: "150", hidden: false }
      ],
      field2: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "days", label: "活动天数(天)", hidden: false},
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "status", label: "上架状态", hidden: false},
        { name: "check_time", label: "上架时间", width:'160', hidden: false},
        { name: "action", label: "操作", width: "150", hidden: false }
      ],
      field3: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "days", label: "活动天数(天)", hidden: false},
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "status", label: "上架状态", hidden: false},
        { name: "check_time", label: "下架架时间", width:'160', hidden: false},
        { name: "action", label: "操作", width: "150", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          title: '',
          days: '',
          status: 2,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        publish: 0, //上架总数
        verify: 0, //审核中总数
        unpublish: 0 //下架总数
      },
      curPowerArr: [],
      curField: '',
    }
  },
  created() {
    this.getTable();
    this.curField = this.field1;
  },
  methods: {
    handleTabs(value) {
      if(value == 1) {
        this.curField = this.field1
      } else if(value == 2) {
        this.curField = this.field2
      } else if(value == 3) {
        this.curField = this.field3
      }
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查询列表
    getTable() {
      this.$http.get('/company/goods/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          this.table.publish = res.data.publish;
          this.table.verify = res.data.verify;
          this.table.unpublish = res.data.unpublish;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row, type) {
      console.log(type)
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row, type)
      dom = null
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 上架，下架
    handleOperate(row) {
      let str = row.status == 3 ? '上架' : '下架'
      let _type = row.status == 3 ? 1 : 2; // 1-上架；2-下架；
      this.$confirm(`请确认${str}操作？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/company/goods/operate', {id: row.id, type: _type}).then(res => { 
          if(res.code === 1) {
            this.$message.success('操作成功！');
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {});
    }
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor3::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}


</style>
